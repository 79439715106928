import React, { useEffect } from "react";
import { incrementStep } from "app/routes/Register/helpers";
import { useHistory, useLocation } from "react-router";
import { useSetAccountTypePreferenceMutation } from "services/graphql";
import { ANALYTICS_MODULE, ANALYTICS_LOCATION } from "constants/index";
import { onboardingSelectDancer } from "services/typewriter/segment";
import { useAnimate } from "../useAnimate";
import { Option } from "../../Option";
import { AccountTypesWrapper } from "./AccountTypesWrapper";
import { ACCOUNT_TYPE_TO_ANALYTICS_VALUES_MAP } from "../../constants";

interface Props {
  accountTypes?: AccountType[];
  isClickDisabled: boolean;
  isEntryAnimationDisabled?: boolean;
  toggleIsClickDisabled(isClickDisabled: boolean): void;
  setAccountType(accountType: string | null): void;
}

interface AccountType {
  description: string;
  label: string;
  previewUrl: string;
  slug: string;
}

interface SetAccountTypeResult {
  slug: string;
}

export function AccountTypes({
  accountTypes = [],
  isClickDisabled,
  isEntryAnimationDisabled = false,
  setAccountType,
  toggleIsClickDisabled,
}: Props) {
  const location = useLocation();
  const history = useHistory();
  const { animationControls, animate } = useAnimate();

  const [setAccountTypePreference] = useSetAccountTypePreferenceMutation({
    onCompleted: ({ setAccountTypePreference: setAccountTypeResult }) => {
      const { slug }: SetAccountTypeResult = setAccountTypeResult;
      setAccountType(slug);

      onboardingSelectDancer({
        module: ANALYTICS_MODULE.onboarding,
        location: ANALYTICS_LOCATION.onboarding,
        dancer_array:
          ACCOUNT_TYPE_TO_ANALYTICS_VALUES_MAP[
            slug as keyof typeof ACCOUNT_TYPE_TO_ANALYTICS_VALUES_MAP
          ],
      });

      incrementStep({ stepsToIncrement: 1, location, history });

      toggleIsClickDisabled(false);
    },
  });

  useEffect(() => {
    setAccountType(null); // reset state when going back
  }, []);

  return (
    <AccountTypesWrapper
      isEntryAnimationDisabled={isEntryAnimationDisabled}
      animationControls={animationControls}
    >
      {accountTypes &&
        accountTypes.map((accountType: AccountType) => (
          <Option
            key={accountType.slug}
            onSelect={() => {
              const { slug } = accountType;

              if (isClickDisabled) {
                return;
              }

              toggleIsClickDisabled(true);

              setTimeout(animate, 200);

              setAccountTypePreference({
                variables: {
                  slug,
                },
              });
            }}
            label={accountType.label}
            slug={accountType.slug}
            description={accountType.description}
          />
        ))}
    </AccountTypesWrapper>
  );
}
